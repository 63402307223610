export const CLIENT_TYPE = 1;
export const CLIENT_SECRET = '';
export const APP_VERSION = '5.0.232.1';
export const COMMIT = '9e03a182ed3ea5fe981740ec7f671637f2c921e8';
export const BRANCH = 'proton-account@5.0.232.1';
export const DATE_VERSION = 'Thu, 03 Apr 2025 16:31:08 GMT';
export const APP_NAME = 'proton-account';
export const API_URL =  '/api';
export const SSO_URL = '';
export const LOCALES = {"en_US":"English","de_DE":"Deutsch","fr_FR":"Français","nl_NL":"Nederlands","es_ES":"Español (España)","es_LA":"Español (Latinoamérica)","it_IT":"Italiano","pl_PL":"Polski","pt_BR":"Português (Brasil)","ru_RU":"Русский","tr_TR":"Türkçe","ca_ES":"Català","cs_CZ":"Čeština","da_DK":"Dansk","fi_FI":"Suomi","hr_HR":"Hrvatski","hu_HU":"Magyar","id_ID":"Bahasa (Indonesia)","kab_DZ":"Taqbaylit","nb_NO":"Norsk bokmål","pt_PT":"Português (Portugal)","ro_RO":"Română","sk_SK":"Slovenčina","sl_SI":"Slovenščina","sv_SE":"Svenska","el_GR":"Ελληνικά","be_BY":"Беларуская","uk_UA":"Українська","ka_GE":"Ქართული","hi_IN":"हिन्दी (भारत)","ko_KR":"한국어","ja_JP":"日本語","zh_CN":"简体中文","zh_TW":"繁體中文"};
export const VERSION_PATH = '/assets/version.json';
export const SENTRY_DSN = 'https://24b0626c1574413eb987b6d35f8cd9c0@sentry/17';
export const SENTRY_DESKTOP_DSN = 'https://8c45c7615ac54aed9a6747430e0a5d8e@sentry/54';